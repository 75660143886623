<template>
  <div class="literatureLimitItem_container" :style="getTopColor(index)">
    <div class="literatureLimitItem_title_number literatureLimitItem_key">
      {{ index + 1 }}
    </div>
    <div
      class="literatureLimitItem_title_document literatureLimitItem_key"
      @click="goToDetail()"
    >
      {{ item.class_content }}
    </div>
    <div class="literatureLimitItem_title_people literatureLimitItem_key">
      <span>{{
        setNew_courseware_number(item) || item.new_courseware_number
      }}</span>
      <!--选择该文献-->
      <el-button
        v-if="item.new_courseware_number < imitsNumber && !item.is_choose"
        size="mini"
        :style="{
          background:
            config.buttonGroup[
              item.is_choose == 1 || coursewareInfo?.courseware_id === item.id
                ? 1
                : 0
            ][mainColor.fontColor].background,
        }"
        @click="doCommit()"
        >{{
          config.buttonGroup[
            item.is_choose == 1 || coursewareInfo?.courseware_id === item.id
              ? 1
              : 0
          ][mainColor.fontColor].title
        }}</el-button
      >
      <!--已选择该文献-->
      <template v-else-if="item.is_choose">
        <el-button
          v-if="item.new_courseware_number == imitsNumber"
          size="mini"
          :style="{
            background:
              config.buttonGroup[
                item.is_choose == 1 || coursewareInfo?.courseware_id === item.id
                  ? 1
                  : 0
              ][mainColor.fontColor].background,
          }"
          @click="doCommit()"
          >{{
            config.buttonGroup[
              item.is_choose == 1 || coursewareInfo?.courseware_id === item.id
                ? 1
                : 0
            ][mainColor.fontColor].title
          }}</el-button
        >
        <el-button
          v-else-if="item.new_courseware_number < imitsNumber"
          size="mini"
          :style="{
            background:
              config.buttonGroup[
                item.is_choose == 1 || coursewareInfo?.courseware_id === item.id
                  ? 1
                  : 0
              ][mainColor.fontColor].background,
          }"
          @click="doCommit()"
          >{{
            config.buttonGroup[
              item.is_choose == 1 || coursewareInfo?.courseware_id === item.id
                ? 1
                : 0
            ][mainColor.fontColor].title
          }}</el-button
        >
      </template>
      <!--该文献已选完-->
      <el-button
        v-else
        size="mini"
        :style="{
          background: config.buttonGroup[2][mainColor.fontColor].background,
        }"
        @click="doCommit()"
        >{{ config.buttonGroup[2][mainColor.fontColor].title }}</el-button
      >
    </div>
  </div>
</template>
<script>
import config from "@/common/config";
import { ElButton } from "element-plus";
export default {
  name: "literatureLimitItem",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    mainColor: {
      type: Object,
      default: () => {
        return { fontColor: "#9b0047", bgColor: "#f7ecef" };
      },
    },
    imitsNumber: {
      type: Number,
      default: () => {
        return 15;
      },
    },
    coursewareInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    ElButton,
  },
  data() {
    return {
      // coursewareInfo: {},
      config: {
        buttonGroup: config.newButtonGroup,
      },
    };
  },
  created() {
    // this.coursewareInfo = this.$tools.getStorage(
    //   "coursewareInfo",
    //   sessionStorage
    // );
  },
  methods: {
    // 设置文献选择状态以及上限数量
    setNew_courseware_number(item) {
      if (this.coursewareInfo?.new_courseware_number) {
        let id = this.coursewareInfo.new_courseware_number.split("_")[0];
        let new_courseware_number =
          this.coursewareInfo.new_courseware_number.split("_")[1];

        if (+item.id === +id) {
          return new_courseware_number;
        }
      }
      return item.new_courseware_number;
    },
    getTopColor(index) {
      // return `color:${this.mainColor.fontColor}`;
      // if (index == 0) return `color:${this.mainColor.fontColor}`;
      if (index % 2 !== 0) return `background:${this.mainColor.bgColor};`;
    },
    doCommit() {
      this.$emit("doCommit", this.item);
    },
    goToDetail() {
      this.$emit("goToDetail", this.item.courseware_id);
    },
  },
};
</script>
<style>
.literatureLimitItem_container {
  width: 100%;
  min-height: 22vw;
  display: flex;
  font-size: 4vw;
  align-items: center;
  justify-content: center;
  color: #003264;
  padding: 2vw 0;
}
.literatureLimitItem_key {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.literatureLimitItem_title_number {
  width: 12vw;
  font-weight: bold;
  /* border-right: 1px #ececec solid; */
}
.literatureLimitItem_title_document {
  flex: 1 0 0;
  word-break: break-all;
  padding: 0 2vw;
}
.literatureLimitItem_title_document .literatureLimitItem_key {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.literatureLimitItem_title_people {
  width: 25vw;
  font-weight: bold;
}
.literatureLimitItem_key {
}
.literatureLimitItem_active {
  background: #f7ecef;
}
.literatureLimitItem_container .el-button--mini {
  border-radius: 20px !important;
  padding: 6px 6px;
  color: #fff;
  width: 90px;
  font-size: 3vw;
}
</style>